import React from "react";

import Button from "../button";

import {
  ContainerButton,
  StyledBox,
  StyledModal,
  TextWarning,
  Warning,
} from "./styles";

import WarningIcon from "../../assets/icons/warning.svg";

const WarningModal = ({ open, handleClose }) => {
  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledBox>
        <Warning>
          <img src={WarningIcon} alt="aviso" />
          ATENÇÃO
        </Warning>
        <TextWarning>
          <span>
            Para participar, é necessário fazer a inscrição primeiro, e
            selecionar a agência, que a mesma irá entrar em contato para fechar
            a reserva do hotel.
          </span>
        </TextWarning>

        <ContainerButton>
          <Button
            onClick={handleClose}
            backgroundColor="#f3b000"
            height={57}
            borderRadius={0}
          >
            Fechar
          </Button>
        </ContainerButton>
      </StyledBox>
    </StyledModal>
  );
};

export default WarningModal;
