import { Container, Content, Description, Logo } from "./styles";

import LogoEvent from "../../assets/clubmed/logo_clubmed.svg";

const DescriptionEvent = () => {
  return (
    <Container id="event">
      <Content>
        <Logo src={LogoEvent} alt="logo" loading="lazy" />

        <Description>
          O Resort Club Med Lake Paradise, em São Paulo, é o destino perfeito
          para recarregar as energias com férias All Inclusive.
        </Description>
        <br />
        <Description>
          A apenas uma hora da capital, oferece relaxamento na piscina, vela na
          represa Taiaçupeba e golfe na Escola de Golfe. Ideal para famílias e
          casais, proporciona uma escapada de fim de semana em meio à natureza
          com diversas atividades aquáticas e terrestres.
        </Description>
        <br />
        <Description>
          O conceito Premium All Inclusive inclui acomodações, refeições e uma
          variedade de esportes e atividades para todas as idades.
        </Description>
      </Content>
    </Container>
  );
};

export default DescriptionEvent;
