import {
  Container,
  Content,
  EventDate,
  EventDescription,
  EventNote,
  EventPeriod,
  Logo,
} from "./styles";

import LogoEvent from "../../assets/clubmed/logo_clubmed_e_caioba.png";

const EventBanner = () => {
  return (
    <Container>
      <Content>
        <Logo alt="logo" loading="lazy" src={LogoEvent} />
        <EventDate>ABRIL DE 2025</EventDate>
        <EventPeriod>
          04 à 06 <span style={{ color: "#F3B000" }}>e</span> 11 à 13
        </EventPeriod>

        <EventDescription>
          O melhor fim de semana da sua família começa agora! Amarre as
          chuteiras, olho na bola e bora jogar!
        </EventDescription>
        <EventNote>
          *O Caioba Edição Club Med acontece em dois finais de semana, escolha a
          melhor data após seguir com a inscrição
        </EventNote>
      </Content>
    </Container>
  );
};

export default EventBanner;
