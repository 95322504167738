import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  background-color: #151515;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 20px;
`;

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: #f3b000;
    font-family: "GothamPro", sans-serif;
    padding: 50px 0;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
`;

export const TableOne = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: auto;

    @media (max-width: 425px) {
      display: none;
    }

    &:nth-of-type(2) {
      display: none;

      @media (max-width: 425px) {
        display: flex;
      }
    }
  }
`;

export const ContainerInformation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  img {
    max-width: 500px;
    width: 100%;
    height: auto;
    display: flex;
  }

  @media (max-width: 1060px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const Line = styled.div`
  height: 2px;
  background-color: #f3b000;
  max-width: 888px;
  width: 100%;
  margin: 50px 0;
`;
