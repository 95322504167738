import React from "react";
import styled from "styled-components";

import LogoImage from "../../assets/c6.svg";
import Icon from "../../assets/pix.svg";

const CardContainer = styled.div`
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  max-width: 367px;
  width: 100%;
  height: 300px;

  @media (max-width: 800px) {
    height: 180px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  height: auto;
  margin-bottom: 10px;

  @media (max-width: 800px) {
    width: 150px;
  }
`;

const ContainerButton = styled.button`
  background-color: #f3b000;
  color: white;
  height: 42px;
  border: none;
  width: 264px;
  font-size: 19px;
  cursor: pointer;
  font-family: "GothamPro";
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  label {
    cursor: pointer;
  }
`;

const Card = ({ isC6 = true, onClick }) => {
  return (
    <CardContainer>
      <Logo src={isC6 === true ? LogoImage : Icon} alt="logo_pix" />
      <ContainerButton onClick={onClick}>
        <label>{isC6 === true ? "Pagamento C6 bank" : "Pagamento  pix"}</label>
      </ContainerButton>
    </CardContainer>
  );
};

export default Card;
