import {
  Container,
  Content,
  Line,
  TableOne,
  ContainerInformation,
} from "./styles";

import TableDesktop from "../../assets/clubmed/table.svg";
import TableMobile from "../../assets/clubmed/table_mobile.svg";

import BedroomSuperior from "../../assets/clubmed/bedroom_superior.svg";
import BedroomDeluxe from "../../assets/clubmed/bedroom_deluxe.svg";

import Policy from "../../assets/clubmed/policy.svg";
import Policy2 from "../../assets/clubmed/policy2.svg";

import PaymentMethod from "../../assets/clubmed/payment method.svg";
import AllInclusive from "../../assets/clubmed/plan_all_inclusive.svg";

const Table = () => {
  return (
    <Container id="tables">
      <Content>
        <TableOne>
          <img src={TableDesktop} alt="tabela" />
          <img src={TableMobile} alt="tabela" />
        </TableOne>

        <Line />

        <ContainerInformation style={{ marginBottom: 50 }}>
          <img src={BedroomSuperior} alt="quarto superior" />
          <img src={BedroomDeluxe} alt="quarto deluxe" />
        </ContainerInformation>

        <ContainerInformation>
          <img src={Policy} alt="política" />
          <img src={Policy2} alt="política" />
        </ContainerInformation>

        <Line />

        <ContainerInformation>
          <img src={PaymentMethod} alt="métodos de pagamento" />
          <img src={AllInclusive} alt="plano all-inclusive" />
        </ContainerInformation>

        <span>
          Após a inscrição, aguarde o e-mail da agência para prosseguir.
        </span>
      </Content>
    </Container>
  );
};

export default Table;
